jQuery(document).ready(function ($) {
    const $container = $('.mixit-container');

    if ($container.length) {
        const mixer = mixitup($container, {
                // selectors: {
                //     control: '[data-mixitup-control]'
                // },
                multifilter: {
                    enable: true
                }
            }
        );
    }
})
